/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
// import MKButton from "components/MKButton";
// import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";
import MKTypography from "components/MKTypography";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

// Images
// import atlassian from "assets/images/logos/small-logos/logo-atlassian.svg";
// import asana from "assets/images/logos/small-logos/logo-asana.svg";
// import shopify from "assets/images/logos/small-logos/logo-shopify.svg";
// import invision from "assets/images/logos/small-logos/logo-invision.svg";
// import slack from "assets/images/logos/small-logos/logo-slack.svg";
// import webdev from "assets/images/logos/small-logos/logo-webdev.svg";

function Features() {
  return (
    <MKBox component="section">
      <MKBox component="section" py={{ xs: 6, lg: 12 }} bgColor="">
        <Container>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={8}>
                  <MKTypography variant="h3" mb={5}>
                    An application adapted to your needs
                  </MKTypography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      icon="payments"
                      title="Simplified rules"
                      description="The rules are simplified and broken down to facilitate understanding"
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="apps"
                      title="Fun learning"
                      description="Challenge your knowledge with 6 available modes"
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      icon="payments"
                      title="Choose where and when"
                      description="We get insulted by others, lose trust for those We get back freezes"
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="3p"
                      title="Coach AI"
                      description="Our AI analyzes your profile to help you progress"
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
              <CenteredBlogCard
                image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                title="join us"
                description="Whether you are a referee, player or fan, you have the opportunity to set your goals."
                action={{
                  type: "internal",
                  route: "pages/company/about-us",
                  color: "info",
                  label: "app store",
                }}
              />
              {/* <MKButton variant="gradient" color="dark">
                appel
              </MKButton> */}
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" py={{ xs: 6, lg: 12 }}>
        <Container>
          <Grid container alignItems="center">
            <Grid item xs={12} lg={5}>
              <MKTypography variant="h3" my={1}>
                Are you a federation, a league, a club, an association ?
              </MKTypography>
              <MKTypography variant="body2" color="text" mb={2}>
                Kalan PRO, a dedicated application to bring together all your users and a dashboard
                to manage your users, lessons, questions and evaluations.
              </MKTypography>
              <MKTypography
                component="a"
                href="#"
                variant="body2"
                color="info"
                fontWeight="regular"
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",

                  "& .material-icons-round": {
                    fontSize: "1.125rem",
                    transform: "translateX(3px)",
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },

                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: "translateX(6px)",
                  },
                }}
              >
                More about Kalan PRO
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
              <Stack>
                <AboutUsOption
                  icon="mediation"
                  content={
                    <>
                      Ability to create lessons and questions for your users.
                      {/* <br />
                      We get our heart broken by people we love. */}
                    </>
                  }
                />
                <AboutUsOption
                  icon="mediation"
                  content={
                    <>
                      Ability to regularly evaluate your users.
                      {/* <br />
                      We get our heart broken by people we love. */}
                    </>
                  }
                />
                <AboutUsOption
                  icon="mediation"
                  content={
                    <>
                      Detailed monitoring of results for each user.
                      {/* <br />
                      We get our heart broken by people we love. */}
                    </>
                  }
                />
                <AboutUsOption
                  icon="settings_overscan"
                  content={
                    <>
                      Help from our AI to personalize learning for each user.
                      {/* <br />
                      Cause pain is what we go through as we become older. */}
                    </>
                  }
                />
                <AboutUsOption
                  icon="token"
                  content={
                    <>
                      Billing based on the number of accesses provided.
                      {/* <br />
                      What else could rust the heart more over time? Blackgold. */}
                    </>
                  }
                />
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" py={{ xs: 6, lg: 12 }} bgColor="">
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={4}
            justifyContent="center"
            mx="auto"
            textAlign="center"
            pb={6}
          >
            <MKTypography variant="h2" mb={1}>
              Soon
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Features that you will be able to find very soon.
            </MKTypography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <SimpleInfoCard
                color="primary"
                icon="person"
                title="New sports"
                description="People in this world shun people for being great. For being a bright color. For standing out."
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SimpleInfoCard
                icon="support_agent"
                title="Quetions with AI"
                description="We get insulted by others, lose trust for those others. We get back here to follow my dreams"
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SimpleInfoCard
                color="warning"
                icon="update"
                title="Evaluations & KalanQuiz"
                description="The time is now to be okay to be the greatest you. Would you believe in what you believe in?"
                direction="center"
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Features;
