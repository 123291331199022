import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Sections components
// import BaseLayout from "layouts/sections/components/BaseLayout";
// import View from "layouts/sections/components/View";

// HelpCenter page components
import FaqCollapse from "examples/FaqCollapse";

// Faq page code
// import faqCode from "layouts/sections/page-sections/faq/code";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={12} my={6} display={"flex"} justifyContent="center">
            <MKTypography
              textAlign={"center"}
              variant="d6"
              textGradient
              // fontWeight="bold"
              color="customColor1"
              mb={5}
              gutterBottom
            >
              Frequently Asked Questions
            </MKTypography>
            <MKBox mb={2}>
              {/* <MKTypography variant="body2" align="center" color="text">
                A lot of people don&apos;t appreciate the moment until it&apos;
              </MKTypography> */}
            </MKBox>
          </Grid>
          <Grid item xs={12} md={8}>
            <FaqCollapse
              title="When Kalan and Kalan PRO will be available ?"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              How to get Kalan and Kalan PRO ?
            </FaqCollapse>
            <FaqCollapse
              title="How to get Kalan and Kalan PRO ?"
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
              don&apos;t appreciate the moment until it&apos;s passed.
            </FaqCollapse>
            <FaqCollapse
              title="What sports are available ?"
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              The time is now for it to bng to do the best way I know how.
            </FaqCollapse>
            <FaqCollapse
              title="Can I unsubscribe at any time ?"
              open={collapse === 4}
              onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
            >
              I always felt like I could ght I could do everything.
              <br />
              <br />
              If everything I did faile
            </FaqCollapse>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
