import { useState, useEffect, useRef } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Sections components
// import BaseLayout from "layouts/sections/components/BaseLayout";
// import View from "layouts/sections/components/View";

// HelpCenter page components
import FaqCollapse from "examples/FaqCollapse";
import MKBadge from "components/MKBadge";
import { motion } from "framer-motion";

// Faq page code
// import faqCode from "layouts/sections/page-sections/faq/code";

function Faq() {
  const [collapse, setCollapse] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const targetRef1 = useRef(null);

  useEffect(() => {
    const observer1 = new IntersectionObserver(
      ([entry]) => {
        console.log(entry);
        if (entry.isIntersecting || entry.intersectionRect.top == 0) {
          setIsVisible1(true);
          console.log("1 : " + entry);
          console.log("1 : " + isVisible1);
        } else {
          setIsVisible1(false);
        }
        // setIsVisible(true);
      },
      { threshold: 0.6 } // Le seuil de 0.5 signifie que l'élément est considéré comme visible lorsqu'au moins 50% de celui-ci est visible dans le viewport
    );

    if (targetRef1.current) {
      observer1.observe(targetRef1.current);
    }

    // Nettoyage de l'observer
    return () => {
      if (targetRef1.current) {
        observer1.unobserve(targetRef1.current);
      }
    };
  }, []);
  const animateParentBoxItems = {
    visible: {
      opacity: 1,
      // x: 0, // Décalage vers la droite
      transition: {
        // when: "beforeChildren",
        staggerChildren: 0.3,
        duration: 0.6,
      },
    },
    hidden: {
      opacity: 0,
      // x: -100, // Décalage vers le haut
      transition: {
        // when: "afterChildren",
        // when: "beforeChildren",
        duration: 0.6,
      },
    },
  };
  const animateBoxItems = {
    visible: {
      opacity: 1,
      // y: 0, // Position initiale à 0 pour les enfants
      filter: "blur(0px)",
      transition: {
        type: "spring",
        duration: 1,
      },
    },
    hidden: {
      opacity: 0,
      // y: -20, // Décalage vers le haut pour les enfants
      filter: "blur(10px)",
      transition: {
        type: "spring",
        duration: 1,
      },
    },
  };
  return (
    <MKBox component="section" bgColor="" py={12} ref={targetRef1}>
      <Container>
        <Grid container justifyContent="center">
          <Grid
            container
            item
            flexDirection="column"
            alignItems="center"
            xs={12}
            lg={6}
            sx={{ textAlign: "center", mx: "auto" }}
            mt={3}
            mb={12}
          >
            <MKBadge
              variant="gradient"
              color="customColor3"
              badgeContent="FAQ"
              container
              circular
              sx={{ mb: 1 }}
            />
            <MKTypography variant="h2" mb={1}>
              Need more Informations ?{" "}
            </MKTypography>
            {/* <MKTypography variant="body2" color="text">
              If you can&apos;t decide, the answer is no. If two equally difficult paths, choose the
              one more painful in the short term (pain avoidance is creating an illusion of
              equality).
            </MKTypography> */}
          </Grid>
          <Grid item xs={12} md={8}>
            <motion.div
              animate={isVisible1 ? "visible" : "hidden"}
              variants={animateParentBoxItems}
              initial="hidden"
            >
              <motion.div variants={animateBoxItems}>
                <FaqCollapse
                  title="When Kalan and Kalan PRO will be available ?"
                  open={collapse === 1}
                  onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                >
                  How to get Kalan and Kalan PRO ?
                </FaqCollapse>
              </motion.div>
              <motion.div variants={animateBoxItems}>
                <FaqCollapse
                  title="How to get Kalan and Kalan PRO ?"
                  open={collapse === 2}
                  onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                >
                  How to get Kalan and Kalan PRO ?
                </FaqCollapse>
              </motion.div>
              <motion.div variants={animateBoxItems}>
                <FaqCollapse
                  title="Are the rules updated ?"
                  open={collapse === 3}
                  onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                >
                  don&apos;t appreciate the moment until it&apos;s passed.
                </FaqCollapse>
              </motion.div>
              <motion.div variants={animateBoxItems}>
                <FaqCollapse
                  title="What sports are available ?"
                  open={collapse === 4}
                  onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                >
                  The time is now for it to bng to do the best way I know how.
                </FaqCollapse>
              </motion.div>
              <motion.div variants={animateBoxItems}>
                <FaqCollapse
                  title="How do I unsubscribe ?"
                  open={collapse === 5}
                  onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
                >
                  The time is now for it to bng to do the best way I know how.
                </FaqCollapse>
              </motion.div>
              <motion.div variants={animateBoxItems}>
                <FaqCollapse
                  title="how long does the training last ?"
                  open={collapse === 6}
                  onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
                >
                  The time is now for it to bng to do the best way I know how.
                </FaqCollapse>
              </motion.div>
              <motion.div variants={animateBoxItems}>
                <FaqCollapse
                  title="how can I contribute ?"
                  open={collapse === 7}
                  onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
                >
                  The time is now for it to bng to do the best way I know how.
                </FaqCollapse>
              </motion.div>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
