/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
import React, { useRef } from "react";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// DesktopApp page sections
import Banner from "pages/KalanLandingPages/sections/Banner.js";
// import Information from "pages/KalanLandingPages/sections/Information";
import Features from "pages/KalanLandingPages/sections/Features";
import Contact from "examples/Contacts/Contact";
import Faq from "pages/KalanLandingPages/sections/Faq";
// import Pricing from "pages/KalanLandingPages/sections/Pricing";
import Why from "pages/KalanLandingPages/sections/Why";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function DesktopApp() {
  const contactRef = useRef(null);
  function scrollToRef() {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <>
      <DefaultNavbar
        goToContactComponent={() => scrollToRef()}
        routes={routes}
        action={{
          type: "anchor",
          // route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "register",
          color: "customColor2",
        }}
        transparent
      />
      <MKBox overflow="clip" bgColor="#27374D">
        {/* <MKBox
          // minHeight="80vh"
          width="100%"
        /> */}
        <Grid sx={{ position: "relative" }} container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Banner goToContactComponent={() => scrollToRef()} />
          </Grid>
          <Grid item xs={12} lg={12}>
            {/* <MKBox
              variant="gradient"
              angle={0}
              bgColor="white"
              className={`bottom-blur`}
              sx={{ ml: "auto", position: "sticky", top: 0, height: 100, width: "100%" }}
            /> */}
            <MKBox
              pt={{ xs: 3, md: 6 }}
              pb={{ xs: 3, md: 12 }}
              // variant="gradient"
              bgColor="white"
            >
              <Why />
            </MKBox>
            <MKBox
              pt={{ xs: 3, md: 6 }}
              pb={{ xs: 3, md: 12 }}
              sx={{
                // mt: 3,
                // backgroundImage: ({ functions: { linearGradient } }) =>
                //   linearGradient("#27374D", "#fff 50%", 0),
                backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                  linearGradient(gradients.customColor2.main, "#fff 70%", 0),
              }}
            >
              <Features />
            </MKBox>
            <Contact ref={contactRef} />
            <MKBox
              pt={{ xs: 3, md: 6 }}
              // pb={{ xs: 3, md: 12 }}
              // variant="gradient"
              bgColor="white"
            >
              <Faq />
              <CenteredFooter content={footerRoutes} />
            </MKBox>
          </Grid>
        </Grid>
        {/* <Information /> */}
        {/* <Pricing /> */}
        {/* <MKBox pt={6} px={1} mt={6}>
        </MKBox> */}
      </MKBox>
    </>
  );
}

export default DesktopApp;
