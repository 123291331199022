/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";

function Pricing() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabType = (event, newValue) => setActiveTab(newValue);

  return (
    <MKBox component="section" py={{ xs: 0, md: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
          <MKTypography variant="h3">Pick the best plan for you</MKTypography>
          <MKTypography variant="body2" color="text">
            You have Free Unlimited Updates and Premium Support on each package.
          </MKTypography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
            <Tabs value={activeTab} onChange={handleTabType}>
              <Tab
                id="monthly"
                label={
                  <MKBox py={0.5} px={2} color="inherit">
                    Kalan
                  </MKBox>
                }
              />
              <Tab
                id="annual"
                label={
                  <MKBox py={0.5} px={2} color="inherit">
                    Kalan PRO
                  </MKBox>
                }
              />
            </Tabs>
          </AppBar>
        </Grid>
        {activeTab == 0 ? (
          <Grid container spacing={3} mt={6} display={"flex"} justifyContent={"center"}>
            <Grid item xs={12} sm={6} lg={3}>
              <SimplePricingCard
                color="dark"
                title="Discovery"
                targets="Players, educators, fans"
                description="You want to know rules of your favorite sport"
                price={{ value: "€0", type: "month" }}
                action={{ type: "internal", route: "/", label: "Start now" }}
                specifications={["Complete documentation", "Working materials in Sketch"]}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <SimplePricingCard
                color="dark"
                title="Premium"
                targets="Referees"
                description="Learn the solid foundation of the rules of your favorite sport"
                price={{ value: "€12,99", type: "month" }}
                action={{ type: "internal", route: "/", label: "Start now" }}
                specifications={[
                  "Complete documentation",
                  "Working materials in Sketch",
                  "2GB cloud storage",
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <SimplePricingCard
                variant="gradient"
                color="dark"
                title="Kalan Ref'"
                targets="Referees"
                description="Your favorite sport will no longer have any secrets for you!"
                price={{ value: "€17,99", type: "month" }}
                action={{ type: "internal", route: "/", label: "Start now" }}
                specifications={[
                  "Complete documentation",
                  "Working materials in Sketch",
                  "20GB cloud storage",
                  "100 team members",
                ]}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3} mt={6} display={"flex"} justifyContent={"center"}>
            <Grid item xs={12} sm={6} lg={3}>
              <SimplePricingCard
                color="dark"
                title="PRO Discovery"
                description="Introduce your users to the rules of your sport"
                targets="Clubs, associations"
                price={{ value: "€159,99", type: "month" }}
                action={{ type: "internal", route: "/", label: "Start now" }}
                specifications={["Complete documentation", "Working materials in Sketch"]}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <SimplePricingCard
                color="dark"
                title="PRO Premium"
                description="Introduce your users to the rules of your sport and follow their results"
                targets="Clubs, associations"
                price={{ value: "€21,90", type: "user / month" }}
                action={{ type: "internal", route: "/", label: "Start now" }}
                specifications={[
                  "Complete documentation",
                  "Working materials in Sketch",
                  "2GB cloud storage",
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <SimplePricingCard
                variant="gradient"
                color="dark"
                title="Kalan PRO Ref'"
                description="To train them in the art of arbitration with our AI"
                targets="Leagues"
                price={{ value: "€28,99", type: "user / month" }}
                action={{ type: "internal", route: "/", label: "Start now" }}
                specifications={[
                  "Complete documentation",
                  "Working materials in Sketch",
                  "20GB cloud storage",
                  "100 team members",
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={9}>
              <Card sx={{ width: "100%" }}>
                <Grid container alignItems="center">
                  <Grid item xs={12} lg={9}>
                    <MKBox py={3} px={4}>
                      <MKTypography variant="h4" mb={1}>
                        Your are a federation or structure with +300 users
                      </MKTypography>
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        We will contact you for personalized and simplified onboarding.
                      </MKTypography>
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <MKBox py={3} px={4}>
                      <Grid container spacing={3}>
                        <MKBox p={3} textAlign="center">
                          <MKButton variant="gradient" color="error" size="large" sx={{ my: 2 }}>
                            Contact us
                          </MKButton>
                        </MKBox>
                      </Grid>
                    </MKBox>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
    </MKBox>
  );
}

export default Pricing;
