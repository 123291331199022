/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import "assets/animations/animations.css";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";
// import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
// import MKButton from "components/MKButton";
// import MKTypography from "components/MKTypography";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React examples
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";
import MKTypography from "components/MKTypography";
// import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
// import iphone from "assets/images/examples/iphone.png";
// import { Swiper, SwiperSlide } from "swiper/react";
import laptop from "assets/images/macbook.png";
import SwiperCore, { Autoplay, Navigation } from "swiper";
// import pxToRem from "assets/theme/functions/pxToRem";
// import pxToRem from "assets/theme/functions/pxToRem";
// import InfoBackgroundCard from "examples/Cards/BackgroundCards/InfoBackgroundCard";
// import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";
// import MKButton from "components/MKButton";

// Images
// import atlassian from "assets/images/logos/small-logos/logo-atlassian.svg";
// import asana from "assets/images/logos/small-logos/logo-asana.svg";
// import shopify from "assets/images/logos/small-logos/logo-shopify.svg";
// import invision from "assets/images/logos/small-logos/logo-invision.svg";
// import slack from "assets/images/logos/small-logos/logo-slack.svg";
// import webdev from "assets/images/logos/small-logos/logo-webdev.svg";
import ListItem from "examples/ListItem";
import iphones from "assets/images/iphones.png";
// import iphones2 from "assets/images/examples/iphone.png";
// import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";
// Images
// import bgImage1 from "assets/images/examples/color1.jpg";
// import bgImage2 from "assets/images/examples/color3.jpg";
// import Typewriter from "assets/theme/functions/typeWriter";
import MKBadge from "components/MKBadge";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import { Card } from "@mui/material";
// import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
// import team1 from "assets/images/team-5.jpg";
// import team2 from "assets/images/bruce-mars.jpg";
// import team3 from "assets/images/ivana-squares.jpg";
// import team4 from "assets/images/ivana-square.jpg";
import { Card } from "@mui/material";
import { motion } from "framer-motion";
const animateTextFeatures = {
  visible: {
    opacity: 1,
    y: 0, // Décalage vers la droite
    // display: "block",
    transition: {
      // when: "beforeChildren",
      staggerChildren: 0.3,
      duration: 0.5,
    },
  },
  hidden: {
    opacity: 0,
    y: 100, // Décalage vers le haut
    // display: "none",
    transition: {
      // when: "afterChildren",
      // when: "beforeChildren",
      duration: 0.5,
    },
  },
};
const animateParentBoxItems = {
  visible: {
    opacity: 1,
    // x: 0, // Décalage vers la droite
    transition: {
      // when: "beforeChildren",
      staggerChildren: 0.3,
      duration: 0.5,
    },
  },
  hidden: {
    opacity: 0,
    // x: -100, // Décalage vers le haut
    transition: {
      // when: "afterChildren",
      // when: "beforeChildren",
      duration: 0.5,
    },
  },
};
const animateBoxItems = {
  visible: {
    opacity: 1,
    // y: 0, // Position initiale à 0 pour les enfants
    filter: "blur(0px)",
    transition: {
      type: "spring",
      duration: 1,
    },
  },
  hidden: {
    opacity: 0,
    // y: -20, // Décalage vers le haut pour les enfants
    filter: "blur(10px)",
    transition: {
      type: "spring",
      duration: 1,
    },
  },
};

function TextFeatureOne({ isVisible }) {
  console.log("isVisible1:", isVisible); // Debugging line to ensure visibility state is correct

  return (
    isVisible && (
      <motion.div
        animate={isVisible ? "visible" : "hidden"}
        variants={animateTextFeatures}
        initial="hidden"
      >
        <Grid
          container
          item
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          xs={10}
          lg={10}
          sx={{ mx: "auto", textAlign: "center" }}
          my={3}
        >
          <MKTypography variant="h3" fontWeight="regular" color="customColor2">
            Learn most complex rules easily
          </MKTypography>
          <MKTypography variant="body2" color="text">
            Whether you want to discover the rules or become an international referee, Kalan adapts
            to your needs
          </MKTypography>
        </Grid>
      </motion.div>
    )
  );
}

TextFeatureOne.propTypes = {
  isVisible: PropTypes.bool,
};

function TextFeatureTwo({ isVisible }) {
  console.log("isVisible1:", isVisible); // Debugging line to ensure visibility state is correct

  return (
    isVisible && (
      <motion.div
        animate={isVisible ? "visible" : "hidden"}
        variants={animateTextFeatures}
        initial="hidden"
      >
        <Grid
          container
          item
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          xs={10}
          lg={10}
          sx={{ mx: "auto", textAlign: "center" }}
          my={3}
        >
          <MKTypography variant="h3" fontWeight="regular" color="customColor2">
            Dedicated features for federations, leagues and associations/clubs...
          </MKTypography>
          <MKTypography variant="body2" color="text">
            Follow and personalize training for each user
          </MKTypography>
        </Grid>
      </motion.div>
    )
  );
}

TextFeatureTwo.propTypes = {
  isVisible: PropTypes.bool,
};

function FeatureOne({ isVisible }) {
  console.log("isVisible1:", isVisible); // Debugging line to ensure visibility state is correct

  return (
    isVisible && (
      <motion.div
        animate={isVisible == false ? "hidden" : "visible"}
        variants={animateParentBoxItems}
        initial="hidden"
      >
        <motion.div variants={animateBoxItems}>
          <ListItem color="dark" title="Simplification of the rules">
            Gain access to the demographics, psychographics, and location of unique people who are
            interested and talk about your brand.
          </ListItem>
        </motion.div>
        <motion.div variants={animateBoxItems}>
          <ListItem color="dark" title="Customizable content">
            Unify data from Facebook, Instagram, Twitter, LinkedIn, and Youtube to gain rich
            insights easy-to use reports.
          </ListItem>
        </motion.div>
        <motion.div variants={animateBoxItems}>
          <ListItem color="dark" title="+500 questions available and 6 quiz modes available">
            Unify data from Facebook, Instagram, Twitter, LinkedIn, and Youtube to gain rich
            insights from easy-to-use reports.
          </ListItem>
        </motion.div>
        <motion.div variants={animateBoxItems}>
          <ListItem color="dark" title="Performance Analysis and Kalan AI">
            Track actions taken on your website that originated from social, and understand the
            impact on your bottom line.
          </ListItem>
        </motion.div>
      </motion.div>
    )
  );
}

FeatureOne.propTypes = {
  isVisible: PropTypes.bool,
};
function FeatureTwo({ isVisible }) {
  console.log("isVisible1:", isVisible); // Debugging line to ensure visibility state is correct

  return (
    isVisible && (
      <motion.div
        animate={isVisible ? "visible" : "hidden"}
        variants={animateParentBoxItems}
        initial="hidden"
      >
        <motion.div variants={animateBoxItems}>
          <ListItem title="Customizable lessons">
            Unify data from Facebook, Instagram, Twitter, LinkedIn, and Youtube to gain rich
            insights from easy-to-use reports.
          </ListItem>
        </motion.div>
        <motion.div variants={animateBoxItems}>
          <ListItem title="Creation of questions and evaluations">
            Unify data from Facebook, Instagram, Twitter, LinkedIn, and Youtube to gain rich
            insights from easy-to-use reports.
          </ListItem>
        </motion.div>
        <motion.div variants={animateBoxItems}>
          <ListItem title="Kalan AI to help you adapt training to each user">
            Track actions taken on your website that originated from social, and understand the
            impact on your bottom line.
          </ListItem>
        </motion.div>
        <motion.div variants={animateBoxItems}>
          <ListItem title="KalanQuiz to help you lead group sessions">
            Track actions taken on your website that originated from social, and understand the
            impact on your bottom line.
          </ListItem>
        </motion.div>
      </motion.div>
    )
  );
}

FeatureTwo.propTypes = {
  isVisible: PropTypes.bool,
};

function Features() {
  // const isMobile = window.matchMedia("(max-width: 768px)").matches;
  SwiperCore.use([Autoplay, Navigation]);
  const [isVisible1, setIsVisible1] = useState(false);
  // const [isVisible2, setIsVisible2] = useState(false);
  const targetRef1 = useRef(null);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabType = (event, newValue) => {
    console.log(newValue);
    setActiveTab(newValue);
  };

  useEffect(() => {
    const observer1 = new IntersectionObserver(
      ([entry]) => {
        console.log(entry);
        if (entry.isIntersecting || entry.intersectionRect.top == 0) {
          setIsVisible1(true);
          console.log("1 : ");
        } else {
          console.log("2 : ");
          setIsVisible1(false);
          console.log(isVisible1 == false);
        }
        // setIsVisible(true);
      },
      { threshold: 0.5 } // Le seuil de 0.5 signifie que l'élément est considéré comme visible lorsqu'au moins 50% de celui-ci est visible dans le viewport
    );

    if (targetRef1.current) {
      observer1.observe(targetRef1.current);
    }

    // Nettoyage de l'observer
    return () => {
      if (targetRef1.current) {
        observer1.unobserve(targetRef1.current);
      }
    };
  }, []);
  const BoxItems = forwardRef(({ children }, ref) => {
    return (
      <MKBox className="box" ref={ref}>
        {children}
      </MKBox>
    );
  });
  // Typechecking props of the MKAlert
  BoxItems.propTypes = {
    children: PropTypes.node,
  };

  // const MotionBoxItems = motion(BoxItems);
  return (
    <MKBox mx={{ xs: 3, lg: 20 }} component="section" ref={targetRef1}>
      <MKBox
        mt={{ xs: 3, lg: 0 }}
        // component="section"
        // bgColor={"customColor4"}
        // variant={"gradient"}
        // angle={0}
      >
        <Container>
          <Grid
            container
            item
            flexDirection="column"
            alignItems="center"
            xs={12}
            lg={6}
            sx={{ textAlign: "center", mx: "auto" }}
          >
            <motion.div
              animate={{ y: isVisible1 ? 0 : 100, opacity: isVisible1 ? 1 : 0 }}
              transition={{ duration: 0.6 }}
            >
              <MKBadge
                variant="gradient"
                color="customColor3"
                badgeContent="Features"
                container
                circular
                sx={{ mb: 1 }}
              />
            </motion.div>
            <motion.div
              animate={{ y: isVisible1 ? 0 : 150, opacity: isVisible1 ? 1 : 0 }}
              transition={{ duration: 0.6 }}
            >
              <MKTypography variant="h2" mb={1}>
                Features that help you optimize your knowledge learning{" "}
              </MKTypography>
            </motion.div>
            {/* <MKTypography variant="body2" color="text">
              If you can&apos;t decide, the answer is no. If two equally difficult paths, choose the
              one more painful in the short term (pain avoidance is creating an illusion of
              equality).
            </MKTypography> */}
          </Grid>
          <Grid item xs={12}>
            <MKBox mb={{ xs: 3, md: 0 }} component="section">
              <Container>
                <motion.div
                  animate={{ y: isVisible1 ? 0 : 200, opacity: isVisible1 ? 1 : 0 }}
                  transition={{ duration: 0.6 }}
                >
                  <Grid container justifyContent={"center"}>
                    <Grid item xs={3}>
                      <AppBar position="static">
                        <Tabs value={activeTab} onChange={handleTabType}>
                          <Tab label="Application" />
                          <Tab label="Back-office" />
                        </Tabs>
                      </AppBar>
                    </Grid>
                  </Grid>
                </motion.div>
              </Container>
            </MKBox>
          </Grid>
          <motion.div
            animate={{ y: isVisible1 ? 0 : 250, opacity: isVisible1 ? 1 : 0 }}
            transition={{ duration: 0.6 }}
          >
            <TextFeatureOne isVisible={activeTab == 0} />
            <TextFeatureTwo isVisible={activeTab == 1} />
          </motion.div>
        </Container>
      </MKBox>
      <motion.div
        animate={{ y: isVisible1 ? 0 : 250, opacity: isVisible1 ? 1 : 0 }}
        transition={{ duration: 0.6 }}
      >
        <Card>
          <Grid container spacing={3} mt={1} mb={5}>
            <Container>
              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent={"center"}
                sx={{ mt: 0 }}
              >
                <Grid
                  item
                  xs={12}
                  md={5}
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <motion.img
                    layoutId="image"
                    src={activeTab == 0 ? iphones : laptop}
                    width={activeTab == 0 ? 300 : 400}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  pr={6}
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <FeatureOne isVisible={activeTab == 0} />
                  <FeatureTwo isVisible={activeTab == 1} />
                  {/* <AnimatePresence>
                    {activeTab == 0 ? (
                      <FeatureOne isVisible={activeTab == 0} />
                    ) : (
                      <FeatureTwo isVisible={activeTab == 1} />
                    )}
                  </AnimatePresence> */}
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Card>
      </motion.div>
    </MKBox>
  );
}

export default Features;
