/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function ListItem({ title, titleVariant, descriptionVariant, color, children, direction }) {
  return (
    <MKBox p={2} display="flex" flexDirection="column" justifyContent={direction}>
      <MKTypography
        variant={titleVariant}
        textAlign={direction}
        fontWeight="regular"
        color={color}
        mb={0}
      >
        {title}
      </MKTypography>
      <MKTypography
        variant={descriptionVariant}
        textAlign={direction}
        color="text"
        display="block"
        mb={1}
      >
        {children}
      </MKTypography>
    </MKBox>
  );
}

// Typechecking props for the ListItem
ListItem.propTypes = {
  direction: PropTypes.string,
  titleVariant: PropTypes.string,
  descriptionVariant: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
ListItem.defaultProps = {
  titleVariant: "h5",
  descriptionVariant: "body2",
  color: "dark",
  direction: "left",
};

export default ListItem;
