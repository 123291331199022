/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
import React, { useRef } from "react";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// DesktopApp page sections
// import Banner from "pages/Kalan/sections/Banner.js";
// import Information from "pages/Kalan/sections/Information";
import Features from "pages/Kalan/sections/Features";
import Why from "pages/Kalan/sections/Why";
import Contact from "examples/Contacts/Contact";
import Faq from "pages/Kalan/sections/Faq";

// Routes
// import routes from "routes";
import footerRoutes from "footer.routes";

function DesktopApp() {
  const contactRef = useRef(null);
  function scrollToRef() {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <>
      <DefaultNavbar
        goToContactComponent={() => scrollToRef()}
        // routes={routes}
        action={{
          type: "anchor",
          // route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "register",
          color: "customColor2",
        }}
        transparent
      />
      <MKBox bgColor="" overflow="clip">
        <MKBox
          minHeight="100px"
          width="100%"
          // sx={{
          //   backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
          //     linearGradient(gradients.customColor2.main, gradients.customColor2.main),
          // }}
        />
        <Why />
        {/* <Banner goToContactComponent={() => scrollToRef()} /> */}
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={10}></Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Features />
          </Grid>
        </Grid>
        {/* <Information /> */}
        <MKBox ref={contactRef} bgColor="">
          <Contact />
        </MKBox>
        <MKBox bgColor="">
          <Faq />
        </MKBox>
        {/* <Pricing /> */}
        <MKBox pt={6} px={1} mt={6}>
          <CenteredFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </>
  );
}

export default DesktopApp;
