/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Typewriter from "assets/theme/functions/typeWriter";

// Images
// import bgPattern from "assets/images/shapes/waves-white.svg";
// import laptop from "assets/images/macbook.png";
// import iphones from "assets/images/iphones.png";

function Banner() {
  return (
    <MKBox
      // variant="gradient"
      // bgColor="customColor1"
      angle={0}
      position="relative"
      // borderRadius="xl"
      // mx={{ xs: 2, xl: 3, xxl: 16 }}
      // mt={12}
      // py={5}
      // px={3}
      // sx={{ overflow: "hidden" }}
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <MKBox
        component="img"
        // src={bgPattern}
        alt="pattern-lines"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        opacity={0.1}
        className={"bottom-blur"}
      />
      <Container sx={{ position: "relative" }}>
        <Grid container>
          <Grid
            display={"flex"}
            justifyContent={"center"}
            // alignItems={"center"}
            flexDirection={"column"}
            item
            xs={12}
            // pb={{ xs: 0, sm: 12 }}
            m="auto"
            position="relative"
          >
            <MKTypography
              fontWeight="regular"
              textGradient
              variant="h1"
              color="customColor2"
              sx={{ fontSize: 160 }}
              className={`animated-item slideFromBottom`}
              // textAlign="center"
            >
              <Typewriter text="Kalan" speed={0} delay={100} duration={0} />
            </MKTypography>
            <MKBox width="50%">
              <MKTypography
                fontWeight="light"
                variant="h1"
                color="white"
                sx={{ fontSize: 32 }}
                // textAlign="center"
              >
                <Typewriter
                  text="your ideal companion for learning"
                  speed={1}
                  delay={400}
                  duration={2000}
                />
                <Typewriter
                  text="the rules of rugby and all these little secrets !"
                  speed={1}
                  delay={400}
                  duration={2000}
                />
              </MKTypography>
            </MKBox>
            <MKTypography
              display="flex"
              flexDirection="column"
              component="a"
              href="#"
              variant="h5"
              color="customColor2"
              fontWeight="regular"
              mt={15}
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateY(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateY(6px)",
                  // textDecoration: "undvaerline",
                },
              }}
            >
              Let&apos;s go
              <Icon sx={{ fontWeight: "bold" }}>keyboard_arrow_down</Icon>
            </MKTypography>
            {/* <MKTypography fontWeight="light" variant="body1" color="text" mb={10}>
              An application to learn the rules of your favorite sport whenever you want, wherever
              you want.
            </MKTypography> */}
            {/* <MKButton
              color="customColor2"
              onClick={() => goToContactComponent()}
              className={`animated-item fadeIn`}
            >
              Register now
            </MKButton> */}
            {/* <MKButton variant="text" color="text" sx={{ ml: 1 }}>
              Read more
            </MKButton> */}
          </Grid>
          {/* <Grid item xs={12} position="absolute" bottom="10%" left="50%" mr={-32} width="70%">
            <MKBox
              component="img"
              src={laptop}
              alt="macbook"
              width="100%"
              // display={{ xs: "none", md: "block" }}
              className={`animated-item slideFromTop`}
            />
          </Grid> */}
          {/* <Grid item xs={12} position="absolute" top="-20%" right="15%" mr={-32} width="40%">
            <MKBox
              component="img"
              src={iphones}
              alt="macbook"
              width="100%"
              display={{ xs: "none", md: "block" }}
              className={`animated-item slideFromTop`}
            />
          </Grid> */}
        </Grid>
      </Container>
      <MKBox></MKBox>
    </MKBox>
  );
}
Banner.propTypes = {
  goToContactComponent: PropTypes.func,
};
export default Banner;
