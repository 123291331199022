/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Switch from "@mui/material/Switch";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
// import Icon from "@mui/material/Icon";
// import Typewriter from "assets/theme/functions/typeWriter";
import MKBadge from "components/MKBadge";
// import Newsletter from "examples/Contacts/Newsletter";
import { Autocomplete, Card } from "@mui/material";
import MKInput from "components/MKInput";
import { motion } from "framer-motion";

function Contact() {
  // const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const [isVisible1, setIsVisible1] = useState(false);
  const targetRef1 = useRef(null);

  useEffect(() => {
    const observer1 = new IntersectionObserver(
      ([entry]) => {
        console.log(entry);
        if (entry.isIntersecting || entry.intersectionRect.top == 0) {
          setIsVisible1(true);
          console.log("1 : " + entry);
          console.log("1 : " + isVisible1);
        } else {
          setIsVisible1(false);
        }
        // setIsVisible(true);
      },
      { threshold: 0.9 } // Le seuil de 0.5 signifie que l'élément est considéré comme visible lorsqu'au moins 50% de celui-ci est visible dans le viewport
    );

    if (targetRef1.current) {
      observer1.observe(targetRef1.current);
    }

    // Nettoyage de l'observer
    return () => {
      if (targetRef1.current) {
        observer1.unobserve(targetRef1.current);
      }
    };
  }, []);

  return (
    <MKBox
      ref={targetRef1}
      component="section"
      display="grid"
      position="relative"
      // minHeight="90vh"
      // variant="gradient"
      // borderRadius="xl"
      angle={0}
      bgColor={"#27374D"}
      sx={{ overflow: { xs: "hidden", lg: "visible" }, placeItems: "center" }}
      // my={{ xs: 6, lg: 12 }}
      // pt={{ xs: 6, lg: 12 }}
      pt={{ xs: 5, lg: 15 }}
      pb={{ xs: 5, lg: 10 }}
      // my={{ xs: 5, lg: 15 }}
    >
      <MKBox
        my={{ xs: 3, lg: 0 }}
        // component="section"
        // bgColor={"customColor4"}
        // variant={"gradient"}
        // angle={0}
      >
        <Container>
          <Grid
            container
            item
            flexDirection="column"
            alignItems="center"
            xs={12}
            // lg={8}
            sx={{ textAlign: "center", mx: "auto" }}
          >
            <MKBadge
              variant="gradient"
              color="customColor3"
              badgeContent="Keep in touch"
              container
              circular
              sx={{ mb: 1 }}
            />
            <MKTypography variant="h2" color="white" mb={1}>
              Register now to be among the first !{" "}
            </MKTypography>
            <MKTypography variant="body2" color="white">
              Leave us your contact details and we will contact you when the application will be
              available.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      {/* <Newsletter /> */}
      <motion.div
        animate={{ scale: isVisible1 ? 1 : 1.2, opacity: isVisible1 ? 1 : 0, width: "100%" }}
        transition={{ duration: 0.3 }}
      >
        <Grid container item xs={12} justifyContent={"center"}>
          <Grid container item xs={12} md={8} my={6}>
            <Card sx={{ width: "100%" }}>
              <Grid container alignItems="center" p={4}>
                {/* <Grid item xs={6}>
                  <MKBox py={3} px={4}>
                    <MKTypography variant="h3" mb={1}>
                      Lifetime Membership
                    </MKTypography>
                    <MKTypography variant="body2" color="text" fontWeight="regular">
                      You have Free Unlimited Updates and Premium Support on each package. You also have
                      30 days to request a refund.
                    </MKTypography>
                    <Grid container item xs={12} lg={3} sx={{ mt: 6, mb: 1 }}>
                      <MKTypography variant="h6">What&apos;s included</MKTypography>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Private code access
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Free entry to all repositories
                          </MKTypography>
                        </MKBox>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Pro member accounts
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Support team full assist
                          </MKTypography>
                        </MKBox>
                      </Grid>
                    </Grid>
                  </MKBox>
                </Grid> */}
                <Grid item xs={12} lg={12}>
                  <Grid container justifyContent="center" sx={{ textAlign: "center", mx: "auto" }}>
                    <Grid
                      // container
                      item
                      xs={12}
                      lg={12}
                      // flexDirection="column"
                      justifyContent="center"
                      mx="auto"
                      // mt={12}
                    >
                      <MKBox component="form" method="" action="">
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item xs={12} md={6}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              Your email
                            </MKTypography>
                            <MKInput
                              type="text"
                              variant="standard"
                              placeholder="myemail@domain.com"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              Interested entity
                            </MKTypography>
                            <Autocomplete
                              defaultValue="a federation or a referee"
                              options={[
                                "a federation",
                                "a league",
                                "a club",
                                "an association",
                                "a player",
                                "a referee",
                                "a fan",
                              ]}
                              renderInput={(params) => <MKInput {...params} variant="standard" />}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <MKButton
                              // variant="gradient"
                              color="customColor2"
                              fullWidth
                              sx={{ height: "100%" }}
                            >
                              Subscribe
                            </MKButton>
                          </Grid>
                        </Grid>
                      </MKBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </motion.div>
    </MKBox>
  );
}

export default Contact;
