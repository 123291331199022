/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";
// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";
// import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
// import MKButton from "components/MKButton";
// import MKTypography from "components/MKTypography";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React examples
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";
import MKTypography from "components/MKTypography";
// import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
// import iphone from "assets/images/examples/iphone.png";
// import { Swiper, SwiperSlide } from "swiper/react";
import laptop from "assets/images/macbook.png";
import SwiperCore, { Autoplay, Navigation } from "swiper";
// import pxToRem from "assets/theme/functions/pxToRem";
// import pxToRem from "assets/theme/functions/pxToRem";
// import InfoBackgroundCard from "examples/Cards/BackgroundCards/InfoBackgroundCard";
// import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";
// import MKButton from "components/MKButton";

// Images
// import atlassian from "assets/images/logos/small-logos/logo-atlassian.svg";
// import asana from "assets/images/logos/small-logos/logo-asana.svg";
// import shopify from "assets/images/logos/small-logos/logo-shopify.svg";
// import invision from "assets/images/logos/small-logos/logo-invision.svg";
// import slack from "assets/images/logos/small-logos/logo-slack.svg";
// import webdev from "assets/images/logos/small-logos/logo-webdev.svg";
import ListItem from "examples/ListItem";
// import iphones from "assets/images/iphones.png";
import iphones2 from "assets/images//examples/iphone.png";
import MKBadge from "components/MKBadge";
// import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";
// Images
// import bgImage1 from "assets/images/examples/color1.jpg";
// import bgImage2 from "assets/images/examples/color3.jpg";
import { motion } from "framer-motion";
function Features() {
  // const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const [isVisible1, setIsVisible1] = useState(false);
  const targetRef1 = useRef(null);
  // const animateWhyItemsFromRight = {
  //   visible: { x: 0, opacity: 1 },
  //   hidden: { x: 100, opacity: 0 },
  // };
  // const animateWhyItemsFromLeft = {
  //   visible: { x: 0, opacity: 1 },
  //   hidden: { x: -100, opacity: 0 },
  // };
  const animateImgWhyItemsFromRight = {
    visible: { x: 0, opacity: 1, scale: 1 },
    hidden: { x: -100, opacity: 0, scale: 0 },
  };
  const animateImgWhyItemsFromLeft = {
    visible: { x: 0, opacity: 1, scale: 1 },
    hidden: { x: 100, opacity: 0, scale: 0 },
  };
  useEffect(() => {
    const observer1 = new IntersectionObserver(
      ([entry]) => {
        // if (!entry.isVisible) {
        setIsVisible1(entry.isIntersecting);
        console.log("1 : " + entry.isIntersecting);
        console.log(entry);
        // }
        // setIsVisible(true);
      },
      { threshold: 0.3 } // Le seuil de 0.5 signifie que l'élément est considéré comme visible lorsqu'au moins 50% de celui-ci est visible dans le viewport
    );
    if (targetRef1.current) {
      observer1.observe(targetRef1.current);
    }
    // Nettoyage de l'observer
    return () => {
      if (targetRef1.current) {
        observer1.unobserve(targetRef1.current);
      }
    };
  }, []);
  SwiperCore.use([Autoplay, Navigation]);

  return (
    <MKBox
      mx={{ xs: 3, lg: 20 }}
      // variant="gradient"
      // bgColor="customColor1"
      component="section"
      ref={targetRef1}
    >
      <MKBox
        // minHeight="80vh"
        // bgColor="customColor2"
        width="100%"
        variant="gradient"
        angle={-60}
        // className={`bottom-blur`}
        zIndex={-1}
        sx={{
          position: "absolute",
          // backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
          //   linearGradient(gradients.customColor2.state, "#fff 80%", 180),
        }}
      />
      <MKBox px={12} pt={{ xs: 3, md: 12 }}>
        <motion.div animate={{ opacity: isVisible1 ? 1 : 0 }} transition={{ duration: 0.3 }}>
          <Grid
            container
            item
            flexDirection="column"
            alignItems="center"
            xs={12}
            lg={6}
            sx={{ textAlign: "center", mx: "auto" }}
          >
            <MKBadge
              variant="gradient"
              color="customColor3"
              badgeContent="Our methodology"
              container
              circular
              sx={{ mb: 1 }}
            />
            <MKTypography variant="h2" mb={1}>
              Learning that adapts to your needs{" "}
            </MKTypography>
            <MKTypography variant="body2" color="text">
              If you can&apos;t decide, the answer is no. If two equally difficult paths, choose the
              one more painful in the short term (pain avoidance is creating an illusion of
              equality).
            </MKTypography>
          </Grid>
        </motion.div>
        <Grid
          container
          // spacing={3}
          alignItems="stretch"
          py={8}
          sx={{ mt: 6, position: "relative" }}
        >
          <Grid item xs={12} md={3} display="flex" flexDirection={"column"} justifyContent="center">
            <motion.div
              animate={{ x: isVisible1 ? 0 : 100, opacity: isVisible1 ? 1 : 0 }}
              transition={{ duration: 0.6 }}
            >
              <ListItem
                titleVariant="d8"
                descriptionVariant="d9"
                color="customColor3"
                direction="right"
                title="Repetition-based learning"
              >
                <motion.div
                  animate={{ x: isVisible1 ? 0 : 150, opacity: isVisible1 ? 1 : 0 }}
                  transition={{ duration: 0.6 }}
                >
                  Gain access to the demographics, psychographics, and location of unique people who
                  are interested and talk about your brand.
                </motion.div>
              </ListItem>
            </motion.div>
            <motion.div
              animate={{ x: isVisible1 ? 0 : 150, opacity: isVisible1 ? 1 : 0 }}
              transition={{ duration: 0.6 }}
            >
              <ListItem
                direction="right"
                titleVariant="d8"
                descriptionVariant="d9"
                color="customColor3"
                title="Simplified content to assimilate more quickly"
              >
                <motion.div
                  animate={{ x: isVisible1 ? 0 : 150, opacity: isVisible1 ? 1 : 0 }}
                  transition={{ duration: 0.6 }}
                >
                  Track actions taken on your website that originated from social, and understand
                  the impact on your bottom line.
                </motion.div>
              </ListItem>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6} sx={{ transform: "rotate(0deg)" }}>
            <Grid container spacing={1} alignItems="center" sx={{ height: "100%" }}>
              <Grid item xs={12} md={6} sx={{ height: "100%", overflow: "hidden" }}>
                <MKBox
                  bgColor=""
                  display="flex"
                  alignItems="center"
                  sx={{ height: "100%", transform: "translateX(100px)" }}
                >
                  <motion.div
                    variants={animateImgWhyItemsFromLeft}
                    animate={isVisible1 ? "visible" : "hidden"}
                    transition={{ duration: 0.5 }}
                  >
                    <MKBox
                      component="img"
                      src={iphones2}
                      alt="macbook"
                      width="100%"
                      display={{ xs: "none", md: "block" }}
                      className={`animated-item slideFromLeft`}
                    />
                  </motion.div>
                </MKBox>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                {isVisible1 && (
                  <MKBox
                    width="1px"
                    height="100%"
                    bgColor="dark"
                    // display={{ xs: "none", md: "block" }}
                    // className={`animated-item slideFromLeft`}
                    ml={-1}
                    sx={{ position: "absolute", zIndex: 100 }}
                    className={`animated-item fadeIn`}
                  />
                )}
                <MKBox
                  bgColor=""
                  display="flex"
                  alignItems="center"
                  sx={{ height: "100%", transform: "translateX(-100px)" }}
                >
                  <motion.div
                    variants={animateImgWhyItemsFromRight}
                    animate={isVisible1 ? "visible" : "hidden"}
                    transition={{ duration: 0.5 }}
                  >
                    <MKBox
                      component="img"
                      src={laptop}
                      alt="macbook"
                      width="100%"
                      display={{ xs: "none", md: "block" }}
                      className={`animated-item slideFromRight`}
                    />
                  </motion.div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} display="flex" flexDirection={"column"} justifyContent="center">
            <motion.div
              animate={{ x: isVisible1 ? 0 : -150, opacity: isVisible1 ? 1 : 0 }}
              transition={{ duration: 0.6 }}
            >
              <ListItem
                direction="left"
                titleVariant="d8"
                descriptionVariant="d9"
                color="customColor3"
                title="When and where you want while being playful"
              >
                <motion.div
                  animate={{ x: isVisible1 ? 0 : -150, opacity: isVisible1 ? 1 : 0 }}
                  transition={{ duration: 0.6 }}
                >
                  Gain access to the demographics, psychographics, and location of unique people who
                  are interested and talk about your brand.
                </motion.div>
              </ListItem>
            </motion.div>
            <motion.div
              animate={{ x: isVisible1 ? 0 : -150, opacity: isVisible1 ? 1 : 0 }}
              transition={{ duration: 0.6 }}
            >
              <ListItem
                direction="left"
                titleVariant="d8"
                descriptionVariant="d9"
                color="customColor3"
                title="Personalize experience using AI"
              >
                <motion.div
                  animate={{ x: isVisible1 ? 0 : -150, opacity: isVisible1 ? 1 : 0 }}
                  transition={{ duration: 0.6 }}
                >
                  Unify data from Facebook, Instagram, Twitter, LinkedIn, and Youtube to gain rich
                  insights from easy-to-use reports.
                </motion.div>
              </ListItem>
            </motion.div>
            {/* <MKBox
              className={`animated-item-slideFromRight slideFromRight`}
              style={{
                animation: "slideFromRight 1s forwards 200ms",
                // Ajoute un délai pour que chaque caractère apparaisse l'un après l'autre
              }}
            >
              <ListItem
                direction="left"
                title="Performance Analyze"
                titleVariant="d8"
                descriptionVariant="d9"
                color="customColor3"
              >
                Track actions taken on your website that originated from social, and understand
                the impact on your bottom line.
              </ListItem>
            </MKBox> */}
            {/* <MKBox
              className={`animated-item-slideFromRight slideFromRight`}
              style={{
                animation: "slideFromRight 1s forwards 300ms",
                // Ajoute un délai pour que chaque caractère apparaisse l'un après l'autre
              }}
            >
              <ListItem
                direction="left"
                titleVariant="d8"
                descriptionVariant="d9"
                color="customColor3"
                title="Performance Analyze"
              >
                Track actions taken on your website that originated from social, and understand
                the impact on your bottom line.
              </ListItem>
            </MKBox> */}
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>
  );
}

export default Features;
