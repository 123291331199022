/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";
// import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
// import MKButton from "components/MKButton";
// import MKTypography from "components/MKTypography";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React examples
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";
import MKTypography from "components/MKTypography";
// import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
// import iphone from "assets/images/examples/iphone.png";
// import { Swiper, SwiperSlide } from "swiper/react";
import laptop from "assets/images/macbook.png";
import SwiperCore, { Autoplay, Navigation } from "swiper";
// import pxToRem from "assets/theme/functions/pxToRem";
// import pxToRem from "assets/theme/functions/pxToRem";
// import InfoBackgroundCard from "examples/Cards/BackgroundCards/InfoBackgroundCard";
// import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";
// import MKButton from "components/MKButton";

// Images
// import atlassian from "assets/images/logos/small-logos/logo-atlassian.svg";
// import asana from "assets/images/logos/small-logos/logo-asana.svg";
// import shopify from "assets/images/logos/small-logos/logo-shopify.svg";
// import invision from "assets/images/logos/small-logos/logo-invision.svg";
// import slack from "assets/images/logos/small-logos/logo-slack.svg";
// import webdev from "assets/images/logos/small-logos/logo-webdev.svg";
import ListItem from "examples/ListItem";
// import iphones from "assets/images/iphones.png";
import iphones2 from "assets/images//examples/iphone.png";
// import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";
// Images
// import bgImage1 from "assets/images/examples/color1.jpg";
// import bgImage2 from "assets/images/examples/color3.jpg";

function Features() {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  SwiperCore.use([Autoplay, Navigation]);
  // const Feature1 = () => {
  //   return (
  //     <MKBox
  //       component="section"
  //       mt={{ xs: 3, lg: 6 }}
  //       mb={{ xs: 1, lg: 6 }}
  //       // mx={{ xs: 3, lg: 0 }}
  //       py={{ xs: 0, lg: 12 }}
  //       bgColor=""
  //       sx={{
  //         position: "relative",
  //       }}
  //     >
  //       {!isMobile && (
  //         <MKBox
  //           borderRadius={100}
  //           bgColor="customColor1"
  //           sx={{
  //             position: "absolute",
  //             top: 30,
  //             right: 30,
  //             left: 30,
  //             bottom: 10,
  //             // transform: `rotate(${isMobile ? "-1deg" : "-3deg"})`,
  //             transform: { xs: "rotate(0deg)", lg: "rotate(-3deg)" },
  //           }}
  //         ></MKBox>
  //       )}
  //       <MKBox
  //         bgColor="customColor1"
  //         borderRadius={isMobile ? 50 : 100}
  //         sx={{
  //           position: "absolute",
  //           top: { xs: 0, lg: 30 },
  //           right: { xs: -5, lg: 30 },
  //           left: { xs: -5, lg: 30 },
  //           bottom: { xs: 0, lg: 30 },
  //           borderBottomRightRadius: { xs: 0, lg: 100 },
  //           borderBottomLeftRadius: { xs: 0, lg: 100 },
  //           // transform: "rotate(3deg)",
  //         }}
  //       ></MKBox>
  //       <Container>
  //         <Grid container alignItems="center">
  //           <Grid item xs={12} lg={1}></Grid>
  //           <Grid item xs={12} lg={6} my={5}>
  //             <Grid container justifyContent="flex-start">
  //               <Grid item xs={12} md={8}>
  //                 <MKBox
  //                   display={"flex"}
  //                   justifyContent={isMobile && "center"}
  //                   alignItems={isMobile && "center"}
  //                 >
  //                   <MKTypography variant="h2" fontWeight="light" color="dark" mb={1}>
  //                     What is&nbsp;
  //                   </MKTypography>
  //                   <MKTypography variant="h2" fontWeight="bold" color="customColor2" mb={1}>
  //                     Kalan ?
  //                   </MKTypography>
  //                 </MKBox>
  //                 <MKTypography
  //                   textAlign={isMobile ? "center" : "left"}
  //                   variant="body2"
  //                   color="dark"
  //                   mb={2}
  //                 >
  //                   A dedicated application to bring together all your users and a dashboard to
  //                   manage your users, lessons, questions and evaluations.
  //                 </MKTypography>
  //               </Grid>
  //               {isMobile && (
  //                 <Grid item xs={12} lg={5} sx={{ ml: "auto", mt: { xs: 10, lg: 0 } }}>
  //                   <CenteredBlogCard image={laptop} />
  //                 </Grid>
  //               )}
  //               <Grid item xs={12} md={6}>
  //                 <MKBox mb={{ xs: 3, md: 5 }}>
  //                   <DefaultInfoCard
  //                     color="dark"
  //                     icon="payments"
  //                     title="Simplified rules"
  //                     description="The rules are simplified and broken down to facilitate understanding"
  //                     direction={isMobile ? "center" : "left"}
  //                   />
  //                 </MKBox>
  //               </Grid>
  //               <Grid item xs={12} md={6}>
  //                 <MKBox mb={{ xs: 3, md: 5 }}>
  //                   <DefaultInfoCard
  //                     color="dark"
  //                     icon="apps"
  //                     title="Fun learning"
  //                     description="Challenge your knowledge with 6 available modes"
  //                     direction={isMobile ? "center" : "left"}
  //                   />
  //                 </MKBox>
  //               </Grid>
  //               <Grid item xs={12} md={6}>
  //                 <MKBox mb={{ xs: 3, md: 5 }}>
  //                   <DefaultInfoCard
  //                     color="dark"
  //                     icon="payments"
  //                     title="Choose where and when"
  //                     description="We get insulted by others, lose trust for those We get back freezes"
  //                     direction={isMobile ? "center" : "left"}
  //                   />
  //                 </MKBox>
  //               </Grid>
  //               <Grid item xs={12} md={6}>
  //                 <MKBox mb={{ xs: 0, md: 5 }}>
  //                   <DefaultInfoCard
  //                     color="dark"
  //                     icon="3p"
  //                     title="Coach AI"
  //                     description="Our AI analyzes your profile to help you progress"
  //                     direction={isMobile ? "center" : "left"}
  //                   />
  //                 </MKBox>
  //               </Grid>
  //               <MKTypography
  //                 component="a"
  //                 href="#"
  //                 variant="h5"
  //                 color="customColor3"
  //                 fontWeight="regular"
  //                 sx={{
  //                   width: "max-content",
  //                   display: "flex",
  //                   alignItems: "center",
  //                   "& .material-icons-round": {
  //                     fontSize: "1.125rem",
  //                     transform: "translateX(3px)",
  //                     transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
  //                   },
  //                   "&:hover .material-icons-round, &:focus .material-icons-round": {
  //                     transform: "translateX(6px)",
  //                     // textDecoration: "underline",
  //                   },
  //                 }}
  //               >
  //                 More about Kalan
  //                 <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
  //               </MKTypography>
  //             </Grid>
  //           </Grid>
  //           {!isMobile && (
  //             <Grid item xs={12} lg={5} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
  //               <CenteredBlogCard image={laptop} />
  //             </Grid>
  //           )}
  //         </Grid>
  //       </Container>
  //     </MKBox>
  //   );
  // };
  // const Feature2 = () => {
  //   return (
  //     <MKBox
  //       component="section"
  //       mt={{ xs: 1, lg: 6 }}
  //       mb={{ xs: 3, lg: 6 }}
  //       // mx={{ xs: 3, lg: 0 }}
  //       py={{ xs: 0, lg: 12 }}
  //       bgColor=""
  //       sx={{
  //         position: "relative",
  //       }}
  //     >
  //       {!isMobile && (
  //         <MKBox
  //           borderRadius={100}
  //           bgColor="customColor1"
  //           sx={{
  //             position: "absolute",
  //             top: 30,
  //             right: 30,
  //             left: 30,
  //             bottom: 10,
  //             transform: `rotate(${isMobile ? "-1deg" : "-3deg"})`,
  //           }}
  //         ></MKBox>
  //       )}
  //       <MKBox
  //         borderRadius={isMobile ? 50 : 100}
  //         bgColor="customColor1"
  //         sx={{
  //           position: "absolute",
  //           top: { xs: 0, lg: 30 },
  //           right: { xs: -5, lg: 30 },
  //           left: { xs: -5, lg: 30 },
  //           bottom: { xs: 0, lg: 30 },
  //           // transform: "rotate(3deg)",
  //           borderTopRightRadius: { xs: 0, lg: 100 },
  //           borderTopLeftRadius: { xs: 0, lg: 100 },
  //         }}
  //       ></MKBox>
  //       <Container>
  //         <Grid container alignItems="center">
  //           <Grid item xs={12} lg={1}></Grid>
  //           <Grid item xs={12} lg={6} my={5}>
  //             <Grid container justifyContent="flex-start">
  //               <Grid item xs={12} md={8}>
  //                 <MKBox
  //                   display={"flex"}
  //                   flexDirection={isMobile ? "column" : "row"}
  //                   justifyContent={isMobile && "center"}
  //                   alignItems={isMobile && "center"}
  //                 >
  //                   <MKTypography
  //                     // display="block"
  //                     variant="h2"
  //                     fontWeight="light"
  //                     color="dark"
  //                   >
  //                     What is&nbsp;
  //                   </MKTypography>
  //                   <MKTypography variant="h2" fontWeight="bold" color="customColor2" mb={1}>
  //                     Kalan PRO ?
  //                   </MKTypography>
  //                 </MKBox>
  //                 <MKTypography
  //                   textAlign={isMobile ? "center" : "left"}
  //                   variant="body2"
  //                   color="dark"
  //                   mb={2}
  //                 >
  //                   A dedicated application to bring together all your users and a dashboard to
  //                   manage your users, lessons, questions and evaluations.
  //                 </MKTypography>
  //               </Grid>
  //               {isMobile && (
  //                 <Grid item xs={12} lg={5} sx={{ ml: "auto", mt: { xs: 10, lg: 0 } }}>
  //                   <CenteredBlogCard image={laptop} />
  //                 </Grid>
  //               )}
  //               <Grid item xs={12} md={6}>
  //                 <MKBox mb={{ xs: 3, md: 5 }}>
  //                   <DefaultInfoCard
  //                     color="dark"
  //                     icon="payments"
  //                     title="Statistics and results"
  //                     description="The rules are simplified and broken down to facilitate understanding"
  //                     direction={isMobile ? "center" : "left"}
  //                   />
  //                 </MKBox>
  //               </Grid>
  //               <Grid item xs={12} md={6}>
  //                 <MKBox mb={{ xs: 3, md: 5 }}>
  //                   <DefaultInfoCard
  //                     color="dark"
  //                     icon="apps"
  //                     title="Custom contents"
  //                     description="Challenge your knowledge with 6 available modes"
  //                     direction={isMobile ? "center" : "left"}
  //                   />
  //                 </MKBox>
  //               </Grid>
  //               <Grid item xs={12} md={6}>
  //                 <MKBox mb={{ xs: 3, md: 5 }}>
  //                   <DefaultInfoCard
  //                     color="dark"
  //                     icon="payments"
  //                     title="Users evaluations"
  //                     description="We get insulted by others, lose trust for those We get back freezes"
  //                     direction={isMobile ? "center" : "left"}
  //                   />
  //                 </MKBox>
  //               </Grid>
  //               <Grid item xs={12} md={6}>
  //                 <MKBox mb={{ xs: 3, md: 5 }}>
  //                   <DefaultInfoCard
  //                     color="dark"
  //                     icon="3p"
  //                     title="Consumption payment"
  //                     description="Our AI analyzes your profile to help you progress"
  //                     direction={isMobile ? "center" : "left"}
  //                   />
  //                 </MKBox>
  //               </Grid>
  //               <MKTypography
  //                 component="a"
  //                 href="#"
  //                 variant="h5"
  //                 color="customColor3"
  //                 fontWeight="regular"
  //                 sx={{
  //                   width: "max-content",
  //                   display: "flex",
  //                   alignItems: "center",

  //                   "& .material-icons-round": {
  //                     fontSize: "1.125rem",
  //                     transform: "translateX(3px)",
  //                     transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
  //                   },

  //                   "&:hover .material-icons-round, &:focus .material-icons-round": {
  //                     transform: "translateX(6px)",
  //                     // textDecoration: "underline",
  //                   },
  //                 }}
  //               >
  //                 More about Kalan PRO
  //                 <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
  //               </MKTypography>
  //             </Grid>
  //           </Grid>
  //           {!isMobile && (
  //             <Grid item xs={12} lg={5} sx={{ ml: "auto", mt: { xs: 10, lg: 0 } }}>
  //               <CenteredBlogCard image={laptop} />
  //             </Grid>
  //           )}
  //         </Grid>
  //       </Container>
  //     </MKBox>
  //   );
  // };
  return (
    <MKBox component="section">
      {/* {
        !isMobile ? (
          <Swiper
            autoplay={{ delay: 5000 }}
            speed={800}
            spaceBetween={100}
            slidesPerView={1}
            navigation
            loop
          >
            <SwiperSlide>{Feature1}</SwiperSlide>
            <SwiperSlide>{Feature2}</SwiperSlide>
          </Swiper>
        ) : (
          <>
            <Feature1 />
            <Feature2 />
          </>
        )
        // {Feature2}
      } */}
      <MKBox mt={{ xs: 3, lg: 0 }} py={{ xs: 3, lg: 6 }} component="section">
        <Container>
          <Grid
            container
            item
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            xs={10}
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKBox textAlign="center">
              <MKBox
                display={"flex"}
                justifyContent={isMobile && "center"}
                alignItems={isMobile && "center"}
              >
                <MKTypography
                  variant="d5"
                  textGradient
                  // fontWeight="bold"
                  color="customColor1"
                  mb={1}
                  className={`animated-item slideFromTop`}
                >
                  About Kalan
                </MKTypography>
              </MKBox>
            </MKBox>
            {/* <MKTypography variant="body2" color="text">
              We&apos;re constantly trying to express ourselves and actualize our dreams. Don&apos;t
              stop.
            </MKTypography> */}
          </Grid>
          <Grid container spacing={3} alignItems="stretch" py={8} sx={{ mt: 6, height: "100%" }}>
            <Grid item xs={12} md={3.5}>
              <MKBox
                className={`animated-item-slideFromLeft slideFromLeft`}
                style={{
                  animation: "slideFromLeft 1s forwards 0ms",
                  // Ajoute un délai pour que chaque caractère apparaisse l'un après l'autre
                }}
              >
                <ListItem
                  titleVariant="d8"
                  descriptionVariant="d9"
                  color="customColor3"
                  direction="right"
                  title="Simplified lessons and questions"
                >
                  Gain access to the demographics, psychographics, and location of unique people who
                  are interested and talk about your brand.
                </ListItem>
              </MKBox>
              {/* <MKBox
                className={`animated-item-slideFromLeft slideFromLeft`}
                style={{
                  animation: "slideFromLeft 1s forwards 100ms",
                  // Ajoute un délai pour que chaque caractère apparaisse l'un après l'autre
                }}
              >
                <ListItem
                  direction="right"
                  titleVariant="d8"
                  descriptionVariant="d9"
                  color="customColor3"
                  title="Repetition-based learning"
                >
                  Unify data from Facebook, Instagram, Twitter, LinkedIn, and Youtube to gain rich
                  insights from easy-to-use reports.
                </ListItem>
              </MKBox> */}
              {/* <MKBox
                className={`animated-item-slideFromLeft slideFromLeft`}
                style={{
                  animation: "slideFromLeft 1s forwards 200ms",
                  // Ajoute un délai pour que chaque caractère apparaisse l'un après l'autre
                }}
              >
                <ListItem
                  direction="right"
                  title="Performance Analyze"
                  titleVariant="d8"
                  descriptionVariant="d9"
                  color="customColor3"
                >
                  Track actions taken on your website that originated from social, and understand
                  the impact on your bottom line.
                </ListItem>
              </MKBox> */}
              <MKBox
                className={`animated-item-slideFromLeft slideFromLeft`}
                style={{
                  animation: "slideFromLeft 1s forwards 300ms",
                  // Ajoute un délai pour que chaque caractère apparaisse l'un après l'autre
                }}
              >
                <ListItem
                  direction="right"
                  titleVariant="d8"
                  descriptionVariant="d9"
                  color="customColor3"
                  title="Performance Analyze"
                >
                  Track actions taken on your website that originated from social, and understand
                  the impact on your bottom line.
                </ListItem>
              </MKBox>
            </Grid>
            <Grid item xs={12} md={5} sx={{ transform: "rotate(0deg)" }}>
              <Grid container spacing={1} alignItems="center" sx={{ height: "100%" }}>
                <Grid item xs={12} md={6} sx={{ height: "100%", overflow: "hidden" }}>
                  <MKBox
                    bgColor=""
                    display="flex"
                    alignItems="center"
                    sx={{ height: "100%", transform: "translateX(100px)" }}
                  >
                    <MKBox
                      component="img"
                      src={iphones2}
                      alt="macbook"
                      width="100%"
                      display={{ xs: "none", md: "block" }}
                      className={`animated-item slideFromLeft`}
                    />
                  </MKBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <MKBox
                    width="1px"
                    height="100%"
                    bgColor="dark"
                    // display={{ xs: "none", md: "block" }}
                    // className={`animated-item slideFromLeft`}
                    ml={-1}
                    sx={{ position: "absolute", zIndex: 100 }}
                    className={`animated-item fadeIn`}
                  />
                  <MKBox
                    bgColor=""
                    display="flex"
                    alignItems="center"
                    sx={{ height: "100%", transform: "translateX(-100px)" }}
                  >
                    <MKBox
                      component="img"
                      src={laptop}
                      alt="macbook"
                      width="100%"
                      display={{ xs: "none", md: "block" }}
                      className={`animated-item slideFromRight`}
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3.5}>
              <MKBox
                className={`animated-item-slideFromRight slideFromRight`}
                style={{
                  animation: "slideFromRight 1s forwards 0ms",
                  // Ajoute un délai pour que chaque caractère apparaisse l'un après l'autre
                }}
              >
                <ListItem
                  direction="left"
                  titleVariant="d8"
                  descriptionVariant="d9"
                  color="customColor3"
                  title="Simplified lessons and questions"
                >
                  Gain access to the demographics, psychographics, and location of unique people who
                  are interested and talk about your brand.
                </ListItem>
              </MKBox>
              <MKBox
                className={`animated-item-slideFromRight slideFromRight`}
                style={{
                  animation: "slideFromRight 1s forwards 100ms",
                  // Ajoute un délai pour que chaque caractère apparaisse l'un après l'autre
                }}
              >
                <ListItem
                  direction="left"
                  titleVariant="d8"
                  descriptionVariant="d9"
                  color="customColor3"
                  title="Repetition-based learning"
                >
                  Unify data from Facebook, Instagram, Twitter, LinkedIn, and Youtube to gain rich
                  insights from easy-to-use reports.
                </ListItem>
              </MKBox>
              {/* <MKBox
                className={`animated-item-slideFromRight slideFromRight`}
                style={{
                  animation: "slideFromRight 1s forwards 200ms",
                  // Ajoute un délai pour que chaque caractère apparaisse l'un après l'autre
                }}
              >
                <ListItem
                  direction="left"
                  title="Performance Analyze"
                  titleVariant="d8"
                  descriptionVariant="d9"
                  color="customColor3"
                >
                  Track actions taken on your website that originated from social, and understand
                  the impact on your bottom line.
                </ListItem>
              </MKBox> */}
              {/* <MKBox
                className={`animated-item-slideFromRight slideFromRight`}
                style={{
                  animation: "slideFromRight 1s forwards 300ms",
                  // Ajoute un délai pour que chaque caractère apparaisse l'un après l'autre
                }}
              >
                <ListItem
                  direction="left"
                  titleVariant="d8"
                  descriptionVariant="d9"
                  color="customColor3"
                  title="Performance Analyze"
                >
                  Track actions taken on your website that originated from social, and understand
                  the impact on your bottom line.
                </ListItem>
              </MKBox> */}
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      {/* <MKBox
        component="section"
        mt={6}
        py={{ xs: 6, lg: 12 }}
        bgColor=""
        sx={{
          position: "relative",
        }}
      >
        <Container>
          <Grid container alignItems="center">
            <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
              <CenteredBlogCard
                image={laptop}
                // title="join us"
                // description="Whether you are a referee, player or fan, you have the opportunity to set your goals."
                // action={{
                //   type: "internal",
                //   route: "pages/company/about-us",
                //   color: "info",
                //   label: "register now",
                // }}
              />
            </Grid>
            <Grid item xs={12} lg={1} mt={-10}></Grid>
            <Grid item xs={12} lg={6} mt={-5}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={8}>
                  <MKBox display={"flex"}>
                    <MKTypography variant="h2" fontWeight="light" color="dark" mb={1}>
                      and what is&nbsp;
                    </MKTypography>
                    <MKTypography variant="h2" fontWeight="bold" color="customColor2" mb={1}>
                      Kalan PRO ?
                    </MKTypography>
                  </MKBox>
                  <MKTypography variant="body2" color="dark" mb={2}>
                    A dedicated application to bring together all your users and a dashboard to
                    manage your users, lessons, questions and evaluations.
                  </MKTypography>
                </Grid>
                <Grid item xs={12}>
                  <MKBox mb={1}>
                    <DefaultInfoCard
                      color="dark"
                      icon="payments"
                      title="Ability to create lessons and questions for your users"
                      // description="The rules are simplified and broken down to facilitate understanding"
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12}>
                  <MKBox mb={1}>
                    <DefaultInfoCard
                      color="dark"
                      icon="payments"
                      title="Ability to regularly evaluate your users"
                      // description="The rules are simplified and broken down to facilitate understanding"
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12}>
                  <MKBox mb={1}>
                    <DefaultInfoCard
                      color="dark"
                      icon="payments"
                      title="Detailed monitoring of results for each user"
                      // description="The rules are simplified and broken down to facilitate understanding"
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12}>
                  <MKBox mb={1}>
                    <DefaultInfoCard
                      color="dark"
                      icon="payments"
                      title="Help from our AI to personalize learning for each user"
                      // description="The rules are simplified and broken down to facilitate understanding"
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      color="dark"
                      icon="payments"
                      title="Billing based on the number of accesses provided"
                      // description="The rules are simplified and broken down to facilitate understanding"
                    />
                  </MKBox>
                </Grid>
                <MKTypography
                  component="a"
                  href="#"
                  variant="h5"
                  color="customColor3"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: "translateX(3px)",
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: "translateX(6px)",
                      // textDecoration: "underline",
                    },
                  }}
                >
                  More about Kalan PRO
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox> */}
      {/* <MKBox mt={6} py={{ xs: 3, lg: 6 }} component="section">
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            justifyContent="center"
            mx="auto"
            mb={6}
          >
            <MKBox py={2} px={6} textAlign="center">
              <MKTypography color="customColor2" variant="h2" mt={2} mb={1}>
                All Sports
              </MKTypography>
              <MKTypography variant="body2" color="text">
                We get insulted by others, lose trust for those others. We get back freezes every
                winter
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid container spacing={3} mb={6}>
            <Grid item xs={12} md={6} lg={4}>
              <ColoredBackgroundCard
                color="dark"
                // image="https://raw.githu<busercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/beach.jpg"
                label="support"
                title="Your favorite sport is not available ?"
                description="If you are a federation or a league, we can add your favorite sport to our panel."
                action={{
                  type: "internal",
                  route: "/sections/page-sections/blog-posts",
                  label: "contact us",
                }}
              />
            </Grid> 
            <Grid container spacing={3} item xs={12} md={6} lg={12}>
              <Grid item xs={12} md={4}>
                <InfoBackgroundCard
                  // image="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/indian.jpg"
                  icon="import_contacts"
                  title="Rugby"
                  label="Available"
                  available={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InfoBackgroundCard
                  // image="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/city.jpg"
                  icon="festival"
                  title="Handball"
                  label="Soon"
                  available={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InfoBackgroundCard
                  // image="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/greece.jpg"
                  icon="volunteer_activism"
                  title="Basket"
                  label="Soon"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InfoBackgroundCard
                  // image="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/laught.jpg"
                  icon="theater_comedy"
                  title="US Football"
                  label="Soon"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InfoBackgroundCard
                  // image="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/laught.jpg"
                  icon="theater_comedy"
                  title="Football"
                  label="Soon"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InfoBackgroundCard
                  // image="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/laught.jpg"
                  icon="theater_comedy"
                  title="MMA"
                  label="Soon"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} lg={12}>
              <Card sx={{ width: "100%", overflow: "hidden" }}>
                <MKBox bgColor="customColor1" py={3} px={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={9}>
                      <MKBox py={3} px={4}>
                        <MKTypography color="customColor2" variant="h4" mb={1}>
                          Your favorite sport is not available ?
                        </MKTypography>
                        <MKTypography color="customColor3" variant="body2" fontWeight="regular">
                          If you are a federation or a league, we can add your favorite sport to our
                          panel.
                        </MKTypography>
                      </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <MKBox py={3} px={4}>
                        <Grid container spacing={3}>
                          <MKBox p={3} textAlign="center">
                            <MKButton color="customColor2" size="large">
                              Contact us
                            </MKButton>
                          </MKBox>
                        </Grid>
                      </MKBox>
                    </Grid>
                  </Grid>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox> */}
      {/* <MKBox mt={6} py={{ xs: 6, lg: 12 }} component="section">
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={4}
            justifyContent="center"
            mx="auto"
            textAlign="center"
            pb={6}
          >
            <MKTypography variant="h2" mb={1} color="customColor2">
              Soon
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Features that you will be able to find very soon.
            </MKTypography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <SimpleInfoCard
                color="primary"
                icon="person"
                title="3 New sports"
                description="People in this world shun people for being great. For being a bright color. For standing out."
                direction={"center"}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SimpleInfoCard
                icon="support_agent"
                title="Questions with AI"
                description="We get insulted by others, lose trust for those others. We get back here to follow my dreams"
                direction={"center"}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SimpleInfoCard
                color="warning"
                icon="update"
                title="Evaluations & KalanQuiz"
                description="The time is now to be okay to be the greatest you. Would you believe in what you believe in?"
                direction={"center"}
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox> */}
    </MKBox>
  );
}

export default Features;
