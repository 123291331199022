import { useState, useEffect } from "react";
import PropTypes from "prop-types";

// const TypingEffect = ({ text, delay, speed, duration }) => {
//   const [displayText, setDisplayText] = useState("");
//   const [currentIndex, setCurrentIndex] = useState(-1);
//   console.log(displayText);

//   useEffect(() => {
//     const interval = setTimeout(() => {
//       setInterval(() => {
//         if (currentIndex < text.length) {
//           setDisplayText((prevText) => prevText + text[currentIndex]);
//           setCurrentIndex((prevIndex) => prevIndex + 1);
//         } else {
//           clearInterval(interval);
//           console.log("terminée");
//         }
//       }, 10);
//     }, delay);

//     return () => clearInterval(interval);
//   }, [currentIndex, text]);

//   return (
//     <div style={{ display: "inline-block" }}>
//       {text.split("").map((char, index) => (
//         <span
//           key={index}
//           style={{
//             opacity: index <= currentIndex ? 1 : 0, // Opacité 1 pour les caractères déjà affichés, 0 pour les autres
//             transition: `opacity ${duration}ms ease ${index * speed}ms`, // Ajoute un délai pour que chaque caractère apparaisse l"un après l"autre
//           }}
//         >
//           {char}
//         </span>
//       ))}
//     </div>
//   );
// };

const TypingEffect = ({ text, delay, speed, duration }) => {
  //   const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    const interval = setTimeout(() => {
      const typingInterval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          if (prevIndex < text.length - 1) {
            // setDisplayText((prevText) => prevText + text[prevIndex + 1]);
            return prevIndex + 1;
          } else {
            clearInterval(typingInterval);
            return prevIndex;
          }
        });
      }, speed);
      return () => clearInterval(typingInterval);
    }, delay);

    return () => clearTimeout(interval);
  }, [delay, speed, text]);
  //   console.log(displayText);
  return (
    <div style={{ display: "inline-block" }}>
      {text.split("").map((char, index) => (
        <span
          key={index}
          style={{
            opacity: index <= currentIndex ? 1 : 0,
            transition: `opacity ${duration}ms ease ${index * speed}ms`,
          }}
        >
          {char}
        </span>
      ))}
    </div>
  );
};

TypingEffect.defaultProps = {
  delay: 0,
  speed: 1000,
  duration: 10000,
};
TypingEffect.propTypes = {
  text: PropTypes.string,
  delay: PropTypes.number,
  speed: PropTypes.number,
  duration: PropTypes.number,
};

export default TypingEffect;
